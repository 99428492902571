<template>
  <strong
    v-if="label"
    class="badge inline-block py-1 px-2.5 text-sm uppercase font-semibold tracking-widest"
    :class="`badge-${toClass(label)}`"
  >
    {{ t(label) }}
  </strong>
</template>

<script setup lang="ts">
import { dash, trim } from 'radash'

const { t } = useI18n()

const props = defineProps<{
  badge: string
}>()

const BLACKLIST: readonly string[] = ['Static']

const label = computed(() => {
  if (BLACKLIST.includes(trim(props.badge))) return null

  return props.badge
})

const toClass = (str: string) => trim(dash(str)).toLocaleLowerCase()
</script>

<!-- @todo: this should be handled at the API level -->
<i18n lang="json">
{
  "es": {
    "Article": "Artículo"
  }
}
</i18n>
